/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a",
    p: "p",
    section: "section",
    h2: "h2",
    div: "div",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {Emoji} = _components;
  if (!Emoji) _missingMdxReference("Emoji", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#github-copilot-is-pretty-cool"
  }, "GitHub Copilot is Pretty Cool")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#asking-for-financial-advice"
  }, "Asking for Financial Advice")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#future-predictions"
  }, "Future Predictions")), "\n"), "\n", React.createElement(_components.p, null, "Recently, I was invited to the GitHub Copilot Technical Preview and have been using the tool for two weeks. So far, I'm pretty impressed with the capability of ", React.createElement(_components.a, {
    href: "https://copilot.github.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "GitHub Copilot"), ". After installing the GitHub Copilot extension for VSCode, I started a project right away and decided on ", React.createElement(_components.a, {
    href: "https://github.com/AndyN9/personalfinance-prime-directive",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "re-creating an income spending flowchart from the /r/personalfinance subreddit"), "."), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "github-copilot-is-pretty-cool",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#github-copilot-is-pretty-cool",
    "aria-label": "github copilot is pretty cool permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "GitHub Copilot is Pretty Cool"), React.createElement(_components.p, null, "As advertised, GitHub Copilot was able to autosuggest working functions from only the name and assist me in filling repetitive array items. Impressively, the AI-powered tool was able to 'read' the context of the project and adjust its suggestions accordingly."), React.createElement(_components.p, null, "Surprisingly, GitHub Copilot was providing me financial advise while I was typing out the individual step descriptions."), React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 650px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/8c2e91d4974f8770ee553dae887a91c3/cab8c/copilot-advice.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 39.263803680981596%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA6klEQVQoz51S2W7EIBDjU3JxZ5ISjhRVrbT//1GuhjTRdh9W2n2wbDwwYwTCWodpmiC1hFQSyqjG7L0DsfiAFDbQ6qCNgrYKSh8Nx3F8GcIai9vnihQWaGOPtFL+w+FNV+1pQuccvPeoe0aKG+Z5BhE1Zl9rfTVWSl3MPvOjFlqzYXD7qfj+qqi1opSCFCNKLsg5I6WEUjJijNj3va0XolYLIbTh7MUtQkilQN5izwnrRzgOxIT8t2ELoQ04GzN751oaY8yVjjWjXZnIY+g79H2Prjv4Hvce62EY2gOcfGqGIFqa8e43eXyUXzS46t9QCj60AAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"GitHub Copilot suggesting to build an emergency fund\"\n        title=\"GitHub Copilot suggesting to build an emergency fund\"\n        src=\"/static/8c2e91d4974f8770ee553dae887a91c3/a6d36/copilot-advice.png\"\n        srcset=\"/static/8c2e91d4974f8770ee553dae887a91c3/222b7/copilot-advice.png 163w,\n/static/8c2e91d4974f8770ee553dae887a91c3/ff46a/copilot-advice.png 325w,\n/static/8c2e91d4974f8770ee553dae887a91c3/a6d36/copilot-advice.png 650w,\n/static/8c2e91d4974f8770ee553dae887a91c3/cab8c/copilot-advice.png 744w\"\n        sizes=\"(max-width: 650px) 100vw, 650px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), React.createElement(_components.p, null, "Let's look into it and get some financial advice from Github Copilot!")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "asking-for-financial-advice",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#asking-for-financial-advice",
    "aria-label": "asking for financial advice permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Asking for Financial Advice"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Disclaimer:"), " This demonstration is for humor purposes only and is not to be taken as actual financial advice."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "q: What should I invest in?\na: You should invest in stocks, bonds, and mutual funds.\n")), React.createElement(_components.p, null, "Is AI advising me to diversify my portfolio?"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "q: Which stock should I invest in?\na: I would invest in the stock with the highest P/E ratio.\n")), React.createElement(_components.p, null, "It's quite interesting that GitHub Copilot delivers general recommendations rather than a specific stock recommendation."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "q: Which crypto should I invest in?\na: I’m not sure. I’m a crypto enthusiast, but I’m not sure what to invest in.\n")), React.createElement(_components.p, null, "I love the honesty of the AI-powered tool ", React.createElement(Emoji, {
    symbol: "🤣"
  }), "!")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "future-predictions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#future-predictions",
    "aria-label": "future predictions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Future Predictions"), React.createElement(_components.p, null, "Despite the fact that I've only been using GitHub Copilot for three weeks, I'm already pleased by its potential to supercharge the developer experience in terms of autosuggestion. More AI-powered code editor tools are something I'm looking forward to seeing in the future.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
